<template>
  <VModalWidget
    :dismiss="dismiss"
    :submit="doSubmit"
    :submitting="working"
    :submitTitle="$t('labels.save')"
    :loading="loading"
  >
    <template v-slot:title>{{ $t('labels.firm') }}</template>

    <template v-slot:content>
      <FormErrors :show="showResourceErrors" :errors="resourceErrors" />
      <v-form>
        <v-text-field
          v-model.trim="form.display"
          :error-messages="displayErrors"
          :label="$t('labels.displayName')"
          required
          @input="$v.form.display.$touch()"
          @blur="$v.form.display.$touch()"
        ></v-text-field>
        <v-text-field
          v-model.trim="form.firm_code"
          :error-messages="codeErrors"
          :label="$t('labels.firmCode')"
          required
          :disabled="!isNew"
          @input="$v.form.firm_code.$touch()"
          @blur="$v.form.firm_code.$touch()"
        ></v-text-field>
        <v-checkbox v-model="form.public" :label="$t('labels.public')" />
      </v-form>
    </template>
  </VModalWidget>
</template>

<script>
import pick from 'lodash/pick'
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { BaseForm, FormErrors, VModalWidget } from '@argon/app/components'
import { Modal } from '@argon/app/mixins'
import { NAMESPACE } from '../store'

export default {
  name: 'ModalFirm',
  extends: BaseForm,
  mixins: [Modal],
  components: {
    FormErrors,
    VModalWidget
  },
  props: {
    firmId: { type: [Number, String], required: true }
  },
  validations: {
    form: {
      display: { required },
      firm_code: { required }
    }
  },
  data: () => ({
    form: {
      display: '',
      firm_code: '',
      public: false
    },
    loading: false,
    working: false
  }),
  computed: {
    isNew() {
      return this.firmId === 'new'
    },
    displayErrors() {
      const errors = []
      if (!this.$v.form.display.$dirty) return errors
      !this.$v.form.display.required && errors.push(this.$t('required'))
      this.getServerErrors('display', errors)
      return errors
    },
    codeErrors() {
      const errors = []
      if (!this.$v.form.firm_code.$dirty) return errors
      !this.$v.form.firm_code.required && errors.push(this.$t('required'))
      this.getServerErrors('firm_code', errors)
      return errors
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['createFirm', 'getFirm', 'patchFirm']),
    doSubmit() {
      this.resetServerErrors()
      if (this.$v.form.$invalid) {
        return this.$v.form.$touch()
      }
      let callback = this.isNew ? this.createFirm : this.patchFirm
      let data = this.isNew ? this.form : { firmId: this.firmId, data: pick(this.form, ['display', 'public']) }
      return callback(data)
        .then((resp) => {
          this.dismiss()
        })
        .catch(this.setServerErrors)
        .finally(() => {
          this.working = false
        })
    }
  },

  created() {
    if (this.isNew) {
      return
    }
    this.getFirm(this.firmId)
      .then((firm) => {
        this.form = pick(firm, ['display', 'firm_code', 'public'])
      })
      .finally(() => {
        this.loading = false
      })
  }
}
</script>
